import React, { useState } from 'react'
import { Button } from '@/components/ui/Buttons'
import { isEmailValid } from '@/utils/Helpers'
import { getAdvisorDisplayName } from '@/utils/Helpers'
import { useRouter } from 'next/router'
import { getCookie } from 'cookies-next'
import { useLocalStorage } from 'hooks/useLocalStorage'
import {
  ForaConversionComponentNames,
  getReferralHistory,
  getURLParameters,
  trackConversionEvent,
} from 'analytics/ForaAnalytics'

export type AdvisorMailingListFormProps = {
  title: string
  firstName: string
  lastName: string
  slug: string
  mailChimpForm: string
  conversionComponentName: ForaConversionComponentNames
}

function extractMailchimpFormAction(html: string): string {
  const regex = /<form.+?action="(.+?\.list-manage\.com\/subscribe\/post.+?)"/
  const match = regex.exec(html)
  if (match && match[1]) {
    return match[1]
  }
  return ''
}

export function AdvisorMailingListForm({
  title,
  firstName,
  lastName,
  slug,
  mailChimpForm,
  conversionComponentName,
}: AdvisorMailingListFormProps): JSX.Element {
  const mailChimpEndpoint = extractMailchimpFormAction(mailChimpForm)
  const [pageReferralHistory] = useLocalStorage<string[]>('page-referrals', [])
  const [emailAddressInput, updateEmailAddress] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)
  const [complete, setComplete] = useState<boolean>(false)
  const router = useRouter()
  const [fbpCookie, fbcCookie, ttpCookie, hubspotCookie] = [
    getCookie('_fbp'),
    getCookie('_fbc'),
    getCookie('_ttp'),
    getCookie('hubspotutk'),
  ]

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEmailAddress(e.target.value)
    if (isEmailValid(e.target.value)) {
      setEmailError(false)
    }
  }

  const submitForm = () => {
    setSubmitting(true)

    const { foraLastSlug1, foraLastSlug2, foraLastSlug3 } =
      getReferralHistory(pageReferralHistory)

    const {
      campaign,
      content,
      fbclid,
      gclid,
      medium,
      source,
      term,
      ttclid,
      msclkid,
    } = getURLParameters(router.query)

    if (isEmailValid(emailAddressInput)) {
      fetch(`/api/forms/advisor-mailing-list`, {
        method: 'post',
        body: JSON.stringify({
          email: emailAddressInput,
          mailChimpEndpoint: mailChimpEndpoint,
          slug: slug,
          pageURL: router.asPath,
          metadata: {
            fbclid,
            gclid,
            ttclid,
            msclkid,
            fbp: fbpCookie,
            fbc: fbcCookie,
            ttp: ttpCookie,
            hubspotutk: hubspotCookie,
            utm: {
              source,
              medium,
              campaign,
              term,
              content,
            },
          },
        }),
      }).then((response) =>
        response.json().then((data) => {
          if (response.status === 200 || response.status === 204) {
            setComplete(true)
            trackConversionEvent({
              advisorName: `${firstName} ${lastName}` || '',
              componentName: conversionComponentName,
              foraFormEmail: emailAddressInput,
              fbc: fbcCookie?.toString() || '',
              fbp: fbpCookie?.toString() || '',
              hubspotCookie: hubspotCookie?.toString() || '',
              ttp: ttpCookie?.toString() || '',
              foraLastSlug1,
              foraLastSlug2,
              foraLastSlug3,
              fbclid,
              gclid,
              ip: data.request_ip,
              label: 'Advisor Mailing List Sign Up',
              ttclid,
              msclkid,
              pagePath: router.pathname,
            })
          }
        })
      )
    } else {
      setEmailError(true)
    }
    setSubmitting(false)
  }
  return mailChimpEndpoint ? (
    <section className="p-12 border border-darkShell">
      <h2 className="pb-4 fora-text-h4 text-[32px] sm:text-[48px]">{`Sign up for ${getAdvisorDisplayName(
        title,
        firstName
      )}'s email list`}</h2>
      <p className="pb-6 fora-text-body-1">
        {!complete
          ? `Stay in-the-know with the latest from
        ${' ' + getAdvisorDisplayName(title, firstName)}, including travel recs,
        hotel features & exclusive perks.`
          : `Thanks for signing up, we'll be in touch!`}
      </p>
      {!complete && (
        <div className="grid grid-cols-3">
          <input
            className="col-span-3 px-6 py-3 mb-6 bg-transparent border outline-none sm:mb-0 sm:col-span-2 fora-text-caption-1 placeholder-stone"
            type="text"
            id="advisor-mailing-list-email"
            autoComplete="email"
            value={emailAddressInput}
            onChange={handleEmailChange}
            placeholder="*Email Address"
          />
          <div className="col-span-3 sm:px-6 sm:col-span-1">
            <Button
              text="Sign Up"
              buttonFn={submitForm}
              theme="primary"
              isDisabled={submitting || complete}
              isFull={true}
            />
          </div>
          {emailError && (
            <p className="col-span-4 pb-6 text-red-80">
              Please enter a valid email address.
            </p>
          )}
        </div>
      )}
    </section>
  ) : (
    <></>
  )
}
