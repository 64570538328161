import { getAdvisorDisplayName } from '@/utils/Helpers'
import { AdvisorProps } from './Types'
import { ItineraryTripReportCardProps } from '../itineraries/Types'
import { AdvisorItineraryCard } from './AdvisorItineraryCard'
import { ButtonLink } from '../ui/ButtonLinks'
import ForaSwiper from '../swiper/ForaSwiper'
import styles from '../swiper/components/featured-hotels.module.css'

export type AdvisorGuidesProps = {
  advisor: AdvisorProps
}

export function AdvisorGuides({ advisor }: AdvisorGuidesProps): JSX.Element {
  const guidesAndTripReports = [
    ...(advisor.linkedFrom?.itinerariesCollection.items || []),
    ...(advisor.linkedFrom?.entryTripReportCollection.items || []),
  ]
  const moreThanOneGuide = guidesAndTripReports.length > 1
  const advisorDisplayName = getAdvisorDisplayName(
    advisor.title,
    advisor.firstName
  )
  const preferredItemIds = new Set(
    advisor.preferredGuidesCollection.items.map((item) => item.sys.id)
  )
  const modifiedGeneralItems = guidesAndTripReports
    .filter((item) => !preferredItemIds.has(item.sys.id))
    .sort((a, b) => {
      const dateA = new Date(a.sys.publishedAt as string).getTime()
      const dateB = new Date(b.sys.publishedAt as string).getTime()
      return dateB - dateA
    })
  const sortedGuides = [
    ...advisor.preferredGuidesCollection.items,
    ...modifiedGeneralItems,
  ]

  return (
    <section className="relative">
      <h2 className="mb-3 max-w-[60%] lg:mb-8 fora-text-h5">
        {advisorDisplayName}&#39;s travel guides & trip reports
      </h2>
      <div>
        <ForaSwiper
          className={styles.foraSwiper}
          params={{
            navigation: true,
            pagination: true,
            keyboard: true,
            slidesPerView: 1.2,
            spaceBetween: 16,
            centeredSlides: false,
            slidesOffsetAfter: moreThanOneGuide ? 60 : 0,
            breakpoints: {
              768: {
                slidesPerView: 1,
                spaceBetween: 24,
                pagination: false,
                slidesOffsetAfter: 0,
              },
            },
          }}
        >
          {sortedGuides.map((itinerary: ItineraryTripReportCardProps, i) => (
            <AdvisorItineraryCard key={`ItineraryCard_${i}`} {...itinerary} />
          ))}
        </ForaSwiper>
      </div>
      {moreThanOneGuide && (
        <div className="flex justify-center mt-6">
          <ButtonLink
            href={{
              pathname: `/travel-guides-itineraries-and-trip-reports`,
              query: { advisor: advisor.slug },
            }}
            target="_self"
            text="VIEW ALL"
            theme="light"
          />
        </div>
      )}
    </section>
  )
}
