import Image from 'next/image'
import Link from 'next/link'
import { ItineraryTripReportCardProps } from '@/components/itineraries/Types'
import { useState } from 'react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { truncateString } from '@/utils/stringManipulation'

interface GetItineraryCardGragmentProps {
  includeCategories?: boolean
}
export const getItineraryCardFragment = ({
  includeCategories = false,
}: GetItineraryCardGragmentProps) => `
sys {
  id
}
title
seoSlug
slugId
slug
image {
  url
  description
}
curatorsStatement {
  raw
  json
}
advisor {
  title
  slug
  advisorVariant
  image {
    url
    description
  }
  cloudinaryImage
  slug
  expertise
}
${
  includeCategories
    ? `categoriesCollection {
  items {
    ... on DestinationsSubcategories {
      title
      slug
    }
    ... on TravelBySubcategories {
      title
      slug
    }
  }
}`
    : ''
} 
`
export const ItineraryCardFragment = `
  sys {
    id
  }
  title
  seoSlug
  slugId
  slug
  image {
    url
    description
  }
  cloudinaryImage
  curatorsStatement {
    json
  }
  advisor {
    title
    slug
    advisorVariant
    image {
      url
      description
    }
    cloudinaryImage
    slug
    expertise
  }
  categoriesCollection {
    items {
      ... on DestinationsSubcategories {
        title
        slug
      }
      ... on TravelBySubcategories {
        title
        slug
      }
    }
  }
`

export function AdvisorItineraryCard({
  __typename,
  title,
  seoSlug,
  slugId,
  image,
  cloudinaryImage,
  advisor,
  curatorsStatement,
}: ItineraryTripReportCardProps): JSX.Element {
  const [isHovered, toggleIsHovered] = useState<boolean>(false)
  const curatorsStatementText = truncateString(
    documentToPlainTextString((curatorsStatement?.json as any) || {}),
    200,
    true
  )
  const cardLink = `/${
    __typename === 'Itineraries' ? 'guides' : 'trip-reports'
  }/${slugId}/${seoSlug}`

  const eyebrow = __typename === 'Itineraries' ? 'Guide' : 'Trip Report'
  return (
    <section
      onMouseEnter={() => toggleIsHovered(true)}
      onMouseLeave={() => toggleIsHovered(false)}
    >
      <Link href={cardLink} className="pb-4 sm:flex md:pb-0">
        <div className="relative sm:w-6/12">
          <div className="relative overflow-hidden w-[283px] h-[283px] md:w-96 md:h-96">
            <div
              className={`w-full h-full transition duration-500 transform ${
                // eslint-disable-next-line prettier/prettier
                isHovered ? 'scale-105' : ''
              }`}
            >
              <Image
                src={cloudinaryImage?.[0]?.secure_url ?? image?.url}
                alt={
                  cloudinaryImage?.[0]?.metadata?.alt ??
                  image?.description ??
                  `${title} curated by ${advisor?.title}`
                }
                fill
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
          <div className="absolute px-2 pt-2 py-1 left-[8px] bottom-[8px] block text-blackSand fora-text-eyebrow-4 uppercase bg-sand">
            {eyebrow}
          </div>
        </div>
        <div className="flex flex-col pt-4 sm:pt-0 sm:w-6/12 sm:pl-12 md:pr-4 lg:pr-0 h-fit">
          {/* <div className="hidden pb-6 xl:block text-blackSand fora-text-subtitle-1">
            {eyebrow}
          </div> */}
          <h2
            className="pb-8 lg:pb-4 xl:pb-8 fora-text-h4 lg:fora-text-h5"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <div className="pb-6 fora-text-body-1">{curatorsStatementText}</div>
          <div className="flex flex-grow items-center">
            <div className="hidden ml-auto uppercase fora-text-navigation-1 sm:block text-darkStone">
              {'Read More >'}
            </div>
          </div>
        </div>
      </Link>
    </section>
  )
}
