import Image from 'next/image'
import { imageLoader } from '@/utils/ImageLoaders'

export type SocialMediaIconListProps = {
  instagramUrl?: string
  tikTokUrl?: string
  facebookUrl?: string
  twitterUrl?: string
  websiteUrl?: string
  pinterestUrl?: string
  linkedinUrl?: string
  youtubeUrl?: string
  size?: number
}

export function SocialMediaIconList({
  instagramUrl,
  tikTokUrl,
  linkedinUrl,
  facebookUrl,
  twitterUrl,
  pinterestUrl,
  youtubeUrl,
  websiteUrl,
  size = 32,
}: SocialMediaIconListProps): JSX.Element {
  return (
    <div>
      <ul className="flex gap-2">
        {instagramUrl && (
          <li>
            <a href={instagramUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1715877420/icon-instagram-black_imkeki.svg"
                alt="Find Fora Advisor on Instagram"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {tikTokUrl && (
          <li>
            <a href={tikTokUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1715877454/icon-tiktok-black_tbk50g.svg"
                alt="Find Fora Advisor on TikTok"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {linkedinUrl && (
          <li>
            <a href={linkedinUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1715877423/icon-linkedin-black_r0yfnv.svg"
                alt="Find Fora Advisor on LinkedIn"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {facebookUrl && (
          <li>
            <a href={facebookUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1715877410/icon-facebook-black_zl9ycu.svg"
                alt="Find Fora Advisor on Facebook"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {twitterUrl && (
          <li>
            <a href={twitterUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1728043392/icon-twitter_b9xhzf.svg"
                alt="Find Fora Advisor on Twitter"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {pinterestUrl && (
          <li>
            <a href={pinterestUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1728043254/icon-pinterest_zz8fui.svg"
                alt="Find Fora Advisor on Pinterest"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {youtubeUrl && (
          <li>
            <a href={youtubeUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1715877468/icon-youtube-black_vm6iwc.svg"
                alt="Find Fora Advisor on Youtube"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {websiteUrl && (
          <li>
            <a href={websiteUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1715877465/icon-website-black_vhn2nr.svg"
                alt="Find Fora Advisor on Website"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}
