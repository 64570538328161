export function truncateString(
  str: string,
  limit: number,
  addEllipses: boolean
): string {
  if (str.length <= limit) {
    return str
  } else {
    let truncatedString = str.substring(0, limit)
    // Check if the last character is part of a word
    if (/\w/.test(str.charAt(limit - 1))) {
      // Find the last space before the limit
      const lastSpaceIndex = truncatedString.lastIndexOf(' ')
      if (lastSpaceIndex !== -1) {
        truncatedString = truncatedString.substring(0, lastSpaceIndex)
      }
    }
    return truncatedString + (addEllipses ? '...' : '')
  }
}

export function lowercaseFirstChar(input: string): string {
  if (!input) return input // Return the original string if it's empty

  // Lowercase the first character and concatenate with the rest of the string
  return input.charAt(0).toLowerCase() + input.slice(1)
}
