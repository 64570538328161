import React from 'react'
import { Hotel, HotelProps } from '../itineraries/Hotel'
import { allAreNull } from '@/utils/Helpers'
import styles from '../swiper/components/featured-hotels.module.css'
import ForaSwiper from '../swiper/ForaSwiper'

interface AdvisorHotelProps {
  hotels: HotelProps[]
}

export function AdvisorHotels({ hotels }: AdvisorHotelProps) {
  const showImages = hotels.every(
    (hotel) =>
      hotel &&
      ((hotel?.image && hotel?.image?.url) ||
        (hotel?.cloudinaryImage && hotel?.cloudinaryImage.length > 0))
  )

  return (
    <section className="relative">
      <h2 className="pb-6 lg:pb-9 fora-text-h5">Favorite hotels</h2>
      <ForaSwiper
        className={styles.foraSwiper}
        params={{
          navigation: true,
          pagination: true,
          keyboard: true,
          slidesPerView: 1.2,
          spaceBetween: 16,
          centeredSlides: false,
          slidesOffsetAfter: 66,
          breakpoints: {
            768: {
              slidesPerView: 2,
              spaceBetween: 24,
              pagination: false,
              slidesOffsetAfter: 0,
            },
          },
        }}
      >
        {!allAreNull(hotels) &&
          hotels
            .filter((item) => item)
            .map((hotel: HotelProps, i: number) => {
              return (
                <div key={`Hotel_${i}`}>
                  <Hotel {...hotel} showImage={showImages} />
                </div>
              )
            })}
      </ForaSwiper>
    </section>
  )
}
